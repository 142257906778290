<template>
  <b-modal
    ref="search-modal"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    centered
    size="xl"
    hide-footer
  >
    <template #modal-title>
      <div
        v-if="$route.name === 'pos-invoice'"
        class="d-flex"
      >
        <span class="mt-50">{{ $t('addNewItem') }}</span>
        <div>
          <b-dropdown
            size="md"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <div v-b-tooltip.hover.top="$t('searchType')">
                &#x1f50d;<span class="sr-only">Setting</span><span class="ml-50 text-body">{{ currentSearch.arabicName }}</span>
              </div>
            </template>
            <b-dropdown-item
              v-for="item in invoiceSearchSettings"
              :key="item.id"
              v-model="currentSearch"
              @click="changeSearch(item)"
            >
              <span class="ml-50">{{ item.arabicName }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <span v-if="$route.name !== 'pos-invoice'">{{ $t('addNewItem') }}</span>
    </template>
    <b-row v-if="storeId">
      <b-col
        v-if="currentBranch.activityType !== 'carShowroom'"
        md="4"
      >
        <g-field
          id="barcode"
          v-silent-search
          :value.sync="filter.barcode"
          label-text="barcode"
          name="barcode"
          @keydown.enter="
            (v) => {
              refreshItems();
            }
          "
        />
      </b-col>
      <b-col
        v-if="currentBranch.activityType === 'carShowroom'"
        md="4"
      >
        <g-field
          id="code"
          v-silent-search
          :value.sync="filter.code"
          label-text="code"
          name="code"
          @keydown.enter="
            (v) => {
              refreshItems();
            }
          "
        />
      </b-col>
      <b-col md="4">
        <g-field
          v-silent-search
          :value.sync="filter.arabicNamePart"
          label-text="arabicName"
          name="arabicName"
          @keydown.enter="
            (v) => {
              refreshItems();
            }
          "
        />
      </b-col>
      <b-col md="4">
        <g-field
          v-silent-search
          :value.sync="filter.englishNamePart"
          label-text="englishName"
          name="arabicName"
          @keydown.enter="
            (v) => {
              refreshItems();
            }
          "
        />
      </b-col>
      <b-col
        cols="12"
        class="pb-2"
      >
        <b-form-checkbox
          v-model="showSelected"
          :value="true"
          class="custom-control-primary"
          @change="() => { totalRows = 0; }"
        >
          {{ $t('showItems') }}
        </b-form-checkbox>
      </b-col>
      <b-col
        cols="12"
        class="medium-window"
      >
        <b-table
          ref="items-table"
          show-empty
          small
          striped
          hover
          responsive
          sticky-column
          class="position-relative"
          primary-key="id"
          :items="showSelected ? items : itemsSearchProvider"
          :fields="tableColumns"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          :sort-direction="sortDirection"
          :busy="isTableBusy"
          :empty-text="$t('noMatchingRecordsFound')"
          :tbody-tr-class="(item) => {
            if (item && item.quantity > item.convertedBalance && this.searchType === 'sales' && item.type === 'rawMaterial' ) return 'table-danger';
            if (item && item.quantity > item.convertedBalance && this.searchType === 'sales' && item.components.length <= 0 && item.type === 'perfectlyMade') return 'table-danger';
            return item && item.quantity ? 'table-primary' : ''
          }"
        >

          <template #head(actions)>
            <span />
          </template>
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :thStyle="field.thStyle"
            >
          </template>
          <template #cell(mainUnitId)="{ item }">
            <g-field
              field="select"
              item-id="unitId"
              placeholder="unit"
              class="select-size-sm"
              :value.sync="item.unitId"
              :options="item.units"
              :clearable="false"
              :label="isRight ? 'unitArabicName' : 'unitEnglishName'"
              :dir="isRight ? 'rtl' : 'ltr'"
              @change="(v) => {
                item.price = v.salesPrice;
                item.costPrice = v.costPrice;
              }"
            />
          </template>
          <template #cell(actions)="{ item }">
            <div class="quantity-input text-nowrap">
              <b-input-group size="sm">
                <b-input-group-prepend>
                  <b-button
                    :disabled="currentBranch.activityType === 'carShowroom' && item.quantity === 1"
                    variant="outline-secondary"
                    @click="
                      (val) => {
                        adaptQuantity(item, 1);
                      }
                    "
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-input-group-prepend>
                <b-form-input
                  v-model.number="item.quantity"
                  size="sm"
                  type="number"
                  :placeholder="$t('quantity')"
                  @change="
                    (val) => {
                      adaptQuantity(item, 0);
                    }
                  "
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-danger"
                    @click="
                      (val) => {
                        adaptQuantity(item, -1);
                      }
                    "
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            :label="$t('entries')"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="perPageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows || items.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-col>
      <b-col
        cols="12"
        class="d-flex justify-content-end py-1 pb-2"
      >
        <b-button
          class="mx-1"
          variant="primary"
          @click="
            (val) => {
              triggerAdding();
            }
          "
        >
          {{ $t('save') }}
        </b-button>
        <b-button
          class="mx-1"
          variant="secondary"
          @click="closeModal"
        >
          {{ $t('Close') }}
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { invoiceSearchSettings } from '@/libs/acl/Lookups';

export default {
  props: {
    storeId: {
      type: [Number, null],
      required: false,
      default: null,
    },
    itemsType: {
      type: [String],
      default: null,
    },
    carColors: {
      type: [Array, Function],
      default: () => [],
    },
    transactionDate: {
      type: [String],
      default: null,
    },
    destinationStoreId: {
      type: [Number, null],
      required: false,
      default: null,
    },
    searchType: {
      type: String,
      default: 'sales',
      validator: (value) => {
        return ['sales', 'purchase'].indexOf(value) !== -1;
      },
    },
    pricelistId: {
      type: [String, Number, null],
      required: false,
      default: null,
    },
    dealerId: {
      type: [Number, null],
      required: false,
      default: null,
    },
  },
  emits: ['addNewItems'],
  data() {
    return {
      perPage: 25,
      sortDirection: 'asc',
      isSortDirDesc: '',
      currentPage: 1,
      sortBy: '',
      filter: {
        barCode: '',
        code: '',
        arabicNamePart: '',
        englishNamePart: '',
        showSelected: '',
        itemType: '',
        OrderClause: '',
        WholeWord: false,
        type: ''
      },
      invoiceSearchSettings,
      isTableBusy: false,
      items: [],
      showSelected: false,
      totalRows: 0,
      priceListArr: [{
        id: null,
        pricelistDate: '',
        code: '',
        arabicName: '',
        englishName: '',
        Notes: '',
        items: [],
      },
      ],
      currentSearch: invoiceSearchSettings[0],
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: this.currentBranch.activityType !== 'carShowroom' ? 'barCode' : 'code',
          label: this.currentBranch.activityType !== 'carShowroom' ? this.$t('barcode') : this.$t('code'),
          sortable: true,
          thStyle: {
            width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '4%' : '10%'
          },
           formatter: (value, key, item) => {
            if (this.currentBranch.activityType !== 'carShowroom') {
            const unit = item.units.find((u) => u.unitId === item.unitId);
             item.barCode = unit.barcode;
              return item.barCode;
            }
            return item.code
         },
        },
        {
          key: this.isRight ? 'arabicName' : 'englishName',
          label: this.$t('name'),
          sortable: true,
          thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '35%' : '60%' },
        },
        {
          key: 'mainUnitId',
          label: this.$t('unit'),
          sortable: true,
          thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '10%' : '20%' },
          tdClass: this.currentBranch.activityType !== 'carShowroom' ? '' : 'd-none',
          thClass: this.currentBranch.activityType !== 'carShowroom' ? '' : 'd-none',
        },
        {
          key: 'count',
          label: this.$t('quantity'),
          sortable: false,
          thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '5%' : '50%' },
          formatter: (value, key, item) => {
            item.convertedBalance = item.currentBalance / (item.units.find((u) => u.unitId === item.unitId).conversionRate || 1);
            return this.fraction(item.convertedBalance);
          },
        },
        {
          key: 'salesPrice',
          label: this.$t('Price'),
          sortable: true,
          thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '15%' : '50%' },
          formatter: (value, key, item) => {
            const unit = item.units.find((u) => u.unitId === item.unitId);
             item.salesPrice = this.fraction(this.searchType === 'sales' ? unit.salesPrice : unit.costPrice);
              return item.salesPrice;
         },
        },
        {
          key: 'actions',
          thStyle: { width: ['lg', 'xl'].indexOf(this.currentBreakPoint) > -1 ? '10%' : '30%' },
        },
      ];
    },
  },
  watch: {
    storeId(newVal) {
      this.filter.storeId = newVal;
    },
    itemsType(newVal) {
      this.filter.type = newVal;
    },
    destinationStoreId(newVal) {
      this.filter.destinationStoreId = newVal;
    },
    pricelistId(newVal) {
      this.filter.priceListId = newVal;
    },
    dealerId(newVal) {
      this.filter.dealerId = newVal;
    }
  },
  mounted() {
    this.filter.storeId = this.storeId;
    this.filter.type = this.itemsType;
    this.filter.priceListId = this.pricelistId;
    this.filter.destinationStoreId = this.destinationStoreId;
    this.filter.dealerId = this.dealerId;
  },
  methods: {
    changeSearch(item) {
      this.currentSearch = item;
      this.filter.WholeWord = item.id
    },
    adaptQuantity(item, count = 0) {
      const quantity = (item.quantity || 0) + count;
      item.quantity = quantity < 0 ? 0 : quantity;

      const selectedItem = this.items.find((val) => val.id === item.id);
      if (this.currentBranch.activityType === 'carShowroom') {
        this.get({ url: 'Items/item-serials', id: item.id }).then((data) => {
          data.forEach(ele => {
          ele.id = ele.serial
          });
          item.serials = data
        })
      }
      if (!selectedItem && item.quantity > 0) this.items.push(item);
      else if (item.quantity === 0 && selectedItem) this.items = this.items.filter((val) => val !== selectedItem);
      else if (selectedItem) selectedItem.quantity = item.quantity;
    },
    refreshItems() {
      this.$refs['items-table'].refresh();
    },
    triggerAdding() {
      const addedItems = this.items.map((item) => {
        return {
          itemArabicName: item.arabicName,
          itemEnglishName: item.englishName,
          unitId: item.unitId,
          itemCode: item.code,
          storeId: this.storeId,
          itemId: item.id,
          quantity: item.quantity,
          convertedBalance: item.convertedBalance,
          price: this.searchType === 'sales' ? item.salesPrice : item.costPrice,
          mainPrice: this.searchType === 'sales' ? item.salesPrice : item.costPrice,
          discountPercentage: this.getDiscountPercentage(item),
          discountValue: this.getDiscountValue(item),
          discountPercentageItem: item.discountPercentage,
          discountValueItem: item.discountValue,
          discountAppliedFromDate: item.discountAppliedFromDate,
          discountAppliedToDate: item.discountAppliedToDate,
          offerQuantity: item.offerQuantity,
          offerFreeQuantity: item.offerFreeQuantity,
          offerAppliedFromDate: item.offerAppliedFromDate,
          offerAppliedToDate: item.offerAppliedToDate,
          total: 0,
          net: 0,
          serial: item.serial,
          serials: item.serials,
          carStatus: item.carStatus,
          carColorId: item.carColorId,
          carColorName: '',
          carStatusArabicName: '',
          attachmentDetails: item.attachmentDetails,
          // stores: item.stores,
          units: item.units,
          isTaxable: item.isTaxable,
          costPrice: item.costPrice, // item.costPrice * item.quantity
          barCode: item.barCode,
          lastSalesPrice: item.lastSalesPrice
        };
      });
      this.$emit('addNewItems', addedItems);
      this.closeModal();
    },
    closeModal() {
      this.items = [];
      this.$refs['search-modal'].hide();
      if (this.$route.name !== 'manufacturing-order-new' || this.$route.name !== 'manufacturing-order-edit') {
      this.filter = {
        barCode: '',
        arabicNamePart: '',
        englishNamePart: '',
        showSelected: '',
        itemType: '',
        storeId: this.storeId,
        priceListId: this.priceListId,
        destinationStoreId: this.destinationStoreId,
        OrderClause: '',
      };
    }
    },
    openModal(type) {
      if (type === 'reservations') {
        type = 'salesOrder'
      }
      this.filter.itemType = type;
      const currentRouteName = this.$route.name;
      if (!this.storeId) {
         this.doneAlert({ text: this.$t('selectStore'), type: 'warning' });
         return
        }
       if (currentRouteName === 'storage-transfer-new' || currentRouteName === 'storage-transfer-edit') {
          if (!this.destinationStoreId) {
            this.doneAlert({ text: this.$t('selectDestinationStore'), type: 'warning' });
            return
          }
      }
      this.$refs['search-modal'].show();
    },
    itemsSearchProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;
      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;
    if (this.pricelistId > 0) {
      this.filter.priceListId = this.pricelistId;
    } else {
      this.filter.priceListId = null;
    }
      params += this.getFilterObj(this.filter);
      this.isTableBusy = true;
      if (this.filter.type === 'perfectlyMade') {
        params += '&isManufacturing=true'
      }
      this.get({ url: `items${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount === 0 ? 1 : totalCount;
          data.forEach((item) => {
            // adapt item object to set prices with each unit based on conversion rate and selected store
            item.quantity = 0;
            const store = item.stores.find((val) => val.storeId === this.storeId) || { currentBalance: 0, costprice: 0 };
            item.currentBalance = store.currentBalance;
            item.costPrice = store.costPrice;
            item.units = item.subUnits.map((unit) => {
              return {
                ...unit,
                itemId: item.id,
                costPrice: store.costPrice * unit.conversionRate,
              };
            });
            item.unitId = item.matchedUnitId || item.mainUnitId;
            item.barCode = item.matchedUnitId ? item.units.find((u) => u.unitId === item.matchedUnitId).barcode : item.mainUnitBarcode;
            item.units.push({
              unitId: item.mainUnitId,
              unitArabicName: item.mainUnitArabicName,
              unitEnglishName: item.mainUnitEnglishName,
              salesPrice: item.salesPrice,
              minSalesPrice: item.minSalesPrice,
              maxSalesPrice: item.maxSalesPrice,
              barcode: item.mainUnitBarcode,
              conversionRate: 1,
              itemId: item.id,
              costPrice: store.costPrice,
              isMain: true,
            });
            item.serial = '';
            item.carStatus = null;
            item.carColorId = null;
            item.costPrice = item.matchedUnitId ? item.units.find((u) => u.unitId === item.matchedUnitId).costPrice : item.units.find((u) => u.unitId === item.unitId).costPrice;
            item.attachmentDetails = [];
            const existItem = this.items.find((val) => val.id === item.id && val.unitId === item.mainUnitId);
            if (existItem) {
              item.quantity = existItem.quantity;
              item.unitId = existItem.unitId;
            }
            return false;
          });
          callback(data);
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
    getDiscountPercentage(item) {
      if (this.currentBranch.discountLevel === 'items' && this.filter.itemType === 'sales') {
        const startDiscount = new Date(item.discountAppliedFromDate).setHours(0, 0, 0);
        const endDiscount = new Date(item.discountAppliedToDate).setHours(0, 0, 0);
        const startOffer = new Date(item.offerAppliedFromDate).setHours(0, 0, 0);
        const endOffer = new Date(item.offerAppliedToDate).setHours(0, 0, 0);
        const date = new Date(this.transactionDate).setHours(0, 0, 0);
        // discounts
        if (startDiscount <= date && date <= endDiscount && (item.discountValue > 0 || item.discountPercentage > 0)) {
          if (item.discountValue > 0) {
            return ((item.discountValue / (item.quantity * item.salesPrice)) * 100).toFixed(2);
          }
          if (item.discountPercentage > 0) {
            return item.discountPercentage;
          }
          return 0;
        }
        // offers
        if (startOffer <= date && date <= endOffer && (item.offerQuantity > 0 || item.offerFreeQuantity > 0)) {
          let requiredQuantity = item.quantity; // 55
          const offer = item.offerQuantity; // 10
          const free = item.offerFreeQuantity; // 2
          let discount = 0;
          while (requiredQuantity > offer) { // 55 > 10
            const d = (requiredQuantity > (offer + free)) ? free : (requiredQuantity - offer); // 55 > 12 ? 2 :45
            discount += d; // 2
            requiredQuantity -= (offer + d); // = 55 - (10 + 2) = 43
          }
          return (((discount * item.salesPrice) / (item.quantity * item.salesPrice)) * 100).toFixed(2);
        }
        return 0
      }
        return 0;
    },
    getDiscountValue(item) {
      if (this.currentBranch.discountLevel === 'items' && this.filter.itemType === 'sales') {
        const startDiscount = new Date(item.discountAppliedFromDate).setHours(0, 0, 0);
        const endDiscount = new Date(item.discountAppliedToDate).setHours(0, 0, 0);
        const startOffer = new Date(item.offerAppliedFromDate).setHours(0, 0, 0);
        const endOffer = new Date(item.offerAppliedToDate).setHours(0, 0, 0);
        const date = new Date(this.transactionDate).setHours(0, 0, 0);
        // discounts
        if (startDiscount <= date && date <= endDiscount && (item.discountValue > 0 || item.discountPercentage > 0)) {
          if (item.discountValue > 0) {
            return item.discountValue;
          }
          if (item.discountPercentage > 0) {
            return ((item.quantity * item.salesPrice) * (item.discountPercentage / 100)).toFixed(2);
          }
          return 0;
        }
        // offers
        if (startOffer <= date && date <= endOffer && (item.offerQuantity > 0 || item.offerFreeQuantity > 0)) {
          let requiredQuantity = item.quantity;
          const offer = item.offerQuantity;
          const free = item.offerFreeQuantity;
          let discount = 0;
          while (requiredQuantity > offer) {
            const d = (requiredQuantity > (offer + free)) ? free : (requiredQuantity - offer);
            discount += d;
            requiredQuantity -= (offer + d);
          }
          return discount * (item.salesPrice || 0);
        }
        return 0;
      }
        return 0;
    }
  },
};
</script>

<style>
  .quantity-input .form-control {
    min-width: 25% !important;
    width: 25% !important;
    text-align: center;
  }
</style>
